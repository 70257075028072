import React, {useState} from 'react'
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import slugify from "slugify";
import Layout from "../components/layout"
import Pagination from "../components/Pagination";
import Seo from "../components/seo"

const CatTemplate = (props) => {
    console.log('props', props)
    const { data } = props
    const [pageIndex, setPageIndex] = useState(1);
    const [perPage] = useState(1200);
    const offSet = (pageIndex-1) * perPage
    
    const AllFencesData = data.AllFences
    const { content, catfence } = data.markdownRemark.frontmatter
    // const CatfenceData = AllFencesData.edges.filter((fitem)=>{
    //     return catfence && catfence.find((item)=>{
    //         fitem.node.frontmatter.slug = slugify(fitem.node.frontmatter.title,{lower: true})
    //         return fitem.node.frontmatter.title === item
    //     })
    // })
    const CatfenceData = AllFencesData.edges.filter((fitem)=>{
       
        return catfence && catfence.find((item)=>{
            console.log('item.toLowerCase()',item.toLowerCase())
            if(fitem.node.frontmatter.slug===item.toLowerCase()){
                console.log('fitem.node.frontmatter.slug',fitem?.node?.frontmatter?.slug)
            }
            return fitem.node.frontmatter.slug === item.toLowerCase()
        })
    })
    const pageCount = Math.ceil(CatfenceData.length / perPage)
   
    const sliceData = CatfenceData.slice(offSet , offSet + perPage)
    return (
        <Layout>
            <div className="bg-white drop-shadow-xl border-t-7 md:px-8 px-4 pb-8 mt-8 max-w-6xl mx-auto ">
                <div className="w-full py-4">
                {content && (
                    <ReactMarkdown
                        children={content}
                        
                        components={{
                        p: ({ children }) => {
                            return (
                                <p className="font-light text-sm text-gray-600 mb-4 max-w-full leading-6">
                                    {children}
                                </p>
                            )
                        },
                        h1: ({ children }) => {
                            return (
                                <h1 className="text-3xl text-gray-600 uppercase font-light title pb-5 mt-7 text-center mb-10">
                                    {children}
                                </h1>
                            )
                        },
                        }}
                    />  
                )}
                </div>
                <div className="categories">
                    <ul className="grid lg:grid-cols-4 md:grid-cols-3 gap-6 m-0 p-0">
                        {sliceData && sliceData.map((item, index)=>{
                            return(
                                <li className="mb-3" key={index}>
                                    <div className="item_box p-5 text-center">
                                        <Link to={`/${item.node.frontmatter.slug}`}><GatsbyImage image={getImage(item.node.frontmatter.featuredimage)} className="w-full mb-5 border border-gray-300" /></Link>
                                        <h3 className="uppercase text-lg font-normal text-center md:h-24 text-gray-700 mb-6">{item.node.frontmatter.title}</h3>
                                        <Link to={`/${item.node.frontmatter.slug}`} className="font1 bg-white border-b border-gray-400 text-sm text-gray-700 inline-block py-2 px-4 uppercase rounded-md font-normal hover:text-cyan-400">Select Style</Link>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                {pageCount>1 && <Pagination pageIndex={pageIndex} setPageIndex={setPageIndex} pageCount={pageCount} currentPage={pageIndex}/> } 
            </div>
        </Layout>
    )
}

export const Head = (props) => <Seo title={props?.data?.markdownRemark?.frontmatter?.meta?.seoTitle || 'Vinyl Fence Toronto : Residential and Commercial Toronto Wood Fencing'}
    description={props?.data?.markdownRemark?.frontmatter?.meta?.seoDescription || 'Vinyl Fence Toronto : Residential and Commercial Toronto Wood Fencing'} path={props.location.pathname}  />
  

export const pageQuery = graphql`
  query CatByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
        frontmatter {
            title
            content
            catfence
            meta {
                seoTitle
                seoDescription
            }
        }
    }
    AllFences: allMarkdownRemark(
        filter: {frontmatter: {templateKey: {eq: "fence-post"}, status: {ne: "Draft"}}}
    ){
        edges {
        node {
            frontmatter {
                title
                slug
                featuredimage {
                    childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 600, height: 500)
                    }
                }
            }
        }
        }
    }
  }
`;
export default CatTemplate